<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
      :isWarehouseRequired="true"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import ActionsDialog from "@/own/components/warehouseManagement/allocationRules/ActionsDialog";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/allocationRules.module";
export default {
  name: "AllocationRules",
  components: { DataTable },
  data: () => ({
    componentForEditing: ActionsDialog,
    componentForAdding: ActionsDialog,
    delete_url: `${process.env.VUE_APP_BASE_URL}/warehouse_management/allocation_rules/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getALLOCATIONRULESTableFilters",
      getTableState: "getALLOCATIONRULESTableState",
      getTableData: "getALLOCATIONRULESTableData",
      getTableHeaders: "getALLOCATIONRULESTableHeaders",
      getTableProperties: "getALLOCATIONRULESTableProperties",
      getTableExportUrl: "getALLOCATIONRULESExportUrl",
      getTablePermissions: "getALLOCATIONRULESTablePermissions",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.selectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
